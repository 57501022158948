.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  @media (--small) {
    padding-top: var(--spacing-96);
  }
}

.introSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin-top: var(--spacing-40);
  padding: var(--section-spacing) 0;

  &__header {
    display: flex;
    flex: 1;
  }

  &__description {
    display: flex;
    flex: 1;
  }

  & span {
    text-align: center;
    margin: var(--spacing-8);

    @extend %typo-body2;
  }

  @media (--small) {
    flex-direction: row;
    align-items: flex-start;

    padding: 0;
    margin: 0;
    margin-bottom: var(--section-spacing);

    &__description {
      display: flex;

      flex: 1;
    }

    & h1 {
      flex: 1;
      display: flex;
    }

    & span {
      flex: 1;
      text-align: start;
      max-width: var(--paragraph-width);
      margin: 0 0 0 var(--spacing-24);

      @extend %typo-body2;
    }
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (--small) {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.image {
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: 100%;
  overflow: hidden;

  justify-content: center;
  align-items: center;

  margin-left: -var(--spacing-16);

  & > div {
    & img {
      aspect-ratio: 7/8;
      object-fit: cover;
    }
  }
}
